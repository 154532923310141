// FUNCTIONS
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}


// PLACEHOLDERS
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}