.form-refine-wrapper {
    overflow: hidden;
    padding: 15px 10px;
    background: #F5F5F5;
    margin-bottom: 15px;

    form {
        margin-bottom: 0;
    }

    .form-group {
        float: left;
        margin-right: 15px;
        margin-bottom: 0px;

        label {
            font-size: 12px;
        }
    }

    .form-fromtill .form-fromtill-input {
        float: left;
        width: 52px;
    }

    .form-fromtill .form-fromtill-text {
        float: left;
        padding: 0 5px;
        line-height: 30px;
        font-size: 12px
    }

    button {
        margin-top: 24px;
        height: 30px;
    }
}

#editobject {

    .panel-object-details {
        dl {
            margin-bottom: 30px;
        }
    }

    .panel-edit-reference {
        .panel-heading {
            overflow: hidden;
        }

        .alert {
            padding: 10px 15px;
            margin-bottom: 10px;
        }

        .select2 {
            margin-bottom: 10px;
            font-size: 12px;

            .select2-selection__rendered {
                &[title="Veel minder"] {
                    background: #EF4933;
                    color: #FFF;
                }
                &[title="Minder"] {
                    background: #F17B38;
                    color: #FFF;
                }
                &[title="Vergelijkbaar"] {
                    background: #FBB138;
                    color: #FFF;
                }
                &[title="Beter"] {
                    background: #5ABC67;
                    color: #FFF;
                }
                &[title="Veel beter"] {
                    background: #2CB469;
                    color: #FFF;
                }

                &[title="Veel minder"] + .select2-selection__arrow b,
                &[title="Minder"] + .select2-selection__arrow b,
                &[title="Vergelijkbaar"] + .select2-selection__arrow b,
                &[title="Beter"] + .select2-selection__arrow b,
                &[title="Veel beter"] + .select2-selection__arrow b {
                    border-top-color: #FFF;
                    border-bottom-color: #FFF;
                }
            }
        }
    }
}

.select2-results {
    ul[id*='ranking'] {
        font-size: 12px;
        
        li:nth-child(1) {
            background: #EF4933;
            color: #FFF;
        }
        li:nth-child(2) {
            background: #F17B38;
            color: #FFF;
        }
        li:nth-child(3) {
            background: #FBB138;
            color: #FFF;
        }
        li:nth-child(4) {
            background: #5ABC67;
            color: #FFF;
        }
        li:nth-child(5) {
            background: #2CB469;
            color: #FFF;
        }
    }
}