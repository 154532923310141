@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    font-size: 16px;
    line-height: 1.6;
    overflow-x: hidden;
    color: #666;
}

.container-fluid {
    margin: 0 auto;
    min-width: 320px;
    max-width: 1440px;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

figure {
    margin: 0;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

.col-xs-margin {
    margin-bottom: 30px;
}

.row-margin-top {
    margin-top: 30px;
}

.wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;

    h1, h4 {
        color: $color-blue;
        font-weight: 400;
        font-size: 30px;
        margin: 0 0 15px 0;
        line-height: 1.5;

        + hr {
            margin-top: 20px;
        }
    }

    h4 {
        font-size: 16px;

        &.modal-title {
            margin-bottom: 0;
        }
    }

    h2, h3 {
        font-size: 18px;
        color: #666;
        font-weight: 700;
        margin: 0 0 15px 0;
    }

    h3 {
        color: $color-orange;
    }

    p {
        margin: 0 0 15px 0;
    }

    a:not(.btn) {
        color: $color-orange;
    }

    ul {
        margin: 0 0 15px 0;
    }

    dl {
        dt, dd {
            text-align: left;
        }
    }

    aside {
        nav {
            padding-top: 15px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    a {
                        display: block;
                        background: #F5F5F5;
                        color: #666;
                        padding: 10px 15px;
                        text-decoration: none;
                        transition: all 0.3s;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #CCC;

                        &:hover {
                            background: #EEE;
                            color: $color-orange;
                        }
                    }

                    &.selected {
                        a {
                            border-bottom: 1px solid $color-orange;
                            background: #EEE;
                            color: $color-orange;
                        }
                    }
                }
            }
        }
    }

    .panel {
        .panel-heading {
            h2, h3 {
                margin-bottom: 0;
                font-weight: 400;
            }
        }

        .panel-body {
            dl {
                margin-bottom: 0;
            }
        }

        .panel-footer {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}

.btn-back {
    display: inline-block;
    margin-bottom: 10px;
}

.table {
    a {
        h4 {
            margin-bottom: 0px;
            color: $color_orange;
        }
    }

    small {
        display: block;
    }
}

hr {
    border: 0;
    color: #CCC;
    background-color: #CCC;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.w100 {
    width: 100%;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.nav-tabs {
    .has-error {
        a {
            color: #a94442 !important;
            font-weight: bold;

            &:after {
                content: ' *';
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .col-xs-margin {
        margin-bottom: 0px;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .dl-horizontal.dl-horizontal-md {
        dt {
            width: 220px;
        }

        dd {
            margin-left: 240px;
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}