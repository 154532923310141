#header_wrapper {
    position: relative;
    z-index: 1;

    &:before {
        position: relative;
        z-index: 100;
        content: " ";
        display: block;
        width: 100%;
        height: 6px;
        background: $color-orange;
        border-top: 6px solid $color-blue;
    }

    header {
        position: relative;
        z-index: 10;
        height: 50px;
        background: #FFF;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

        img.logo {
            display: block;
            height: 32px;
            margin: 9px 0;
        }

        button {
            position: absolute;
            top: 0;
            right: 0px;
            width: 50px;
            height: 50px;
            margin: 0;
            padding: 0;
            border: 0;
            outline: none;
            font-size: 24px;
            color: #666;
            border-left: 1px solid #CCC;
            background: #FFF;

            &#btn_user {
                right: 50px;
            }

            &#btn_search {
                right: 100px;
            }

            &:hover {
                background: #F5F5F5;
                color: $color-orange;
            }
        }

    }

    > .container-fluid {
        position: relative;
        padding: 0;
    }

    .subwrapper {
        display: none;
        padding: 15px;
        background: #EEE;
        border-bottom: 1px solid #CCC;

        &#nav_wrapper {
            padding: 0;
            border-bottom: none;

            > .container-fluid {
                padding: 0;
            }
        }

        nav {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    a {
                        padding: 10px 15px;
                        display: block;
                        background: #FFF;
                        color: #666;
                        text-decoration: none;
                        border-left: 4px solid #CCC;
                        border-bottom: 1px solid #CCC;

                        &:hover {
                            background: #F5F5F5;
                            border-color: #999;
                        }
                    }

                    &.selected {
                        > a {
                            background: #F5F5F5;
                            border-bottom-color: $color-orange;
                            border-left-color: $color-orange;
                        }
                    }

                }
            }

            > ul {
                > li {
                    > ul {
                        display: none;

                        > li {
                            > a {
                                background: #F5F5F5;

                                &:before {
                                    content: '- ';
                                }

                                &:hover {
                                    background: #EEE;
                                }
                            }

                            &.selected {
                                > a {
                                    background: #EEE;
                                }
                            }
                        }
                    }

                    &.selected {
                        > ul {
                            display: block;
                        }
                    }
                }
            }
        }

        form {
            position: relative;

            input {
                width: 100%;
                padding: 8px 10px;
                border-radius: 3px;
                border: 1px solid #CCC;
                font-size: 14px;
            }

            &.search {
                button {
                    position: absolute;
                    top: 3px;
                    right: 0px;
                    font-size: 14px;
                    background: none;
                    border: none;
                    color: #5f5f5f;
                    padding: 5px 10px;
                }
            }

            &.login {
                input {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    #header_wrapper {
        &:before {
            border-top: 10px solid $color-blue;
            height: 10px;
        }

        header {
            box-shadow: none;
            height: 80px;

            img.logo {
                height: 40px;
                margin: 20px 0;
                float: left;
            }

            h2 {
                border-left: 1px solid #999;
                float: left;
                color: $color-blue;
                margin: 31px 0 0 15px;
                font-size: 14px;
                font-weight: normal;
                padding-left: 15px;
            }

            .metanav {
                position: absolute;
                right: 220px;
                top: 22px;
                list-style: none;
                background: #F5F5F5;
                padding: 5px 10px;
                border-radius: 3px;

                li {
                    display: inline-block;
                    font-size: 14px;

                    &:not(:last-child) {
                        border-right: 1px solid #CCC;
                        padding-right: 10px;
                        margin-right: 6px;
                    }

                    a {
                        color: #666;
                    }
                }
            }

            button#btn_user {
                border: none;
                height: 33px;
                right: 15px;
                font-size: 14px;
                background: #e1e1e1;
                padding: 5px 10px;
                border-radius: 3px;
                top: 23px;
                width: 180px;
                text-align: left;
                padding-right: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span.icon-user {
                    margin-right: 5px;
                }

                &:after {
                    position: absolute;
                    right: 8px;
                    top: 13px;
                    content: " ";
                    display: block;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #666;
                }
            }
        }

        .subwrapper {
            display: block;

            &#nav_wrapper {
                background: #F5F5F5;
                border-bottom: 1px solid #CCC;

                > .container-fluid {
                    padding: 0 15px;
                }
            }

            nav {
                ul {
                    display: flex;

                    li {
                        position: relative;
                        display: block;
                        float: left;
                        width: 20%;
                        padding: 7px 0;
                        background: #F5F5F5;

                        a {
                            border: none;
                            text-align: center;
                            padding: 0;
                            border-right: 1px solid #CCC;
                            background: none;
                        }

                        &:first-child {
                            a {
                                border-left: 1px solid #CCC;
                            }
                        }
                    }
                }

                > ul {
                    > li {
                        > ul {
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 35px;
                            z-index: 1;
                            min-width: 100%;
                            border-left: 4px solid #999999;
                            border-right: 1px solid #CCC;
                            max-height: 0px;
                            overflow: hidden;
                            transition: all 0.2s linear;

                            > li {
                                display: block;
                                width: auto;
                                float: none;
                                background: #FFF;
                                border-bottom: 1px solid #CCC;

                                &:first-child {
                                    border-top: 4px solid #999;
                                }

                                > a {
                                    white-space: nowrap;
                                    border: none !important;
                                    background: none;
                                    text-align: left;
                                    padding: 0 15px;

                                    &:before {
                                        content: none;
                                    }

                                    &:hover {
                                        background: none;
                                        color: $color-orange;
                                    }
                                }

                                &.selected {
                                    border-bottom: 1px solid $color-orange;

                                    > a {
                                        background: none;
                                        color: $color-orange;
                                    }
                                }
                            }
                        }

                        &:after {
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            display: block;
                            content: " ";
                            height: 4px;
                            width: 100%;
                            background: transparent;
                            transition: all 0.3s;
                        }

                        &.selected {
                            &:after {
                                background: $color-orange;
                            }
                        }

                        &:hover {
                            > ul {
                                max-height: 400px;
                            }

                            &:after {
                                background: #999;
                            }
                        }
                    }
                }
            }

            &#search_wrapper {
                padding: 0;
                border: 0;
                background: none;
                position: absolute;
                right: 215px;
                top: -97px;
                z-index: 100;

                form {
                    input {
                        padding: 6px 10px;
                        width: 200px;
                    }

                    button {
                        top: 0px;
                    }
                }

                .flash-error {
                    position: absolute;
                    display: block;
                    font-size: 12px;
                    color: #a94442;
                    background: #f2dede;
                    border: 1px solid #ebccd1;
                    padding: 5px;
                    cursor: pointer;
                }
            }

            &#user_wrapper {
                display: none;
                position: absolute;
                right: 15px;
                top: -52px;
                border: 1px solid #CCC;
                z-index: 100;
                border-radius: 3px;

                &:before {
                    position: absolute;
                    right: 45px;
                    top: -8px;
                    content: " ";
                    display: block;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid #CCC;
                }

                &:after {
                    position: absolute;
                    right: 45px;
                    top: -6px;
                    content: " ";
                    display: block;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid #EEE;
                }

                form {
                    input {
                        display: block;
                        padding: 6px 10px;
                        width: 200px;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {

}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {

}
