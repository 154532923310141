#footer_wrapper {
    background: #e7e7e7;
    color: #666;
    font-size: 14px;
    padding-top: 30px;

    h2 {
        font-weight: 400;
        font-size: 16px;
        color: $color-blue;
        margin: 0 0 15px 0;
    }

    a {
        color: #666;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    address {
        font-style: normal;
        margin-bottom: 0;
    }

    .footer_bottom {
        margin-top: 20px;
        border-top: 1px solid #CCC;
        padding: 20px 0;
    }

    .social {
        li {
            display: block;
            float: left;
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            a {
                display: block;
                color: #FFF;
                text-decoration: none;
                width: 50px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-size: 22px;
                border-radius: 3px;
                transition: all 0.3s;
            }

            &.social_rss {
                a {
                    background: #ff9900;
                    font-size: 18px;

                    &:hover {
                        background: shade(#ff9900, 10%);
                    }
                }
            }

            &.social_facebook {
                a {
                    background: #3c5a99;

                    &:hover {
                        background: shade(#3c5a99, 10%);
                    }
                }
            }

            &.social_twitter {
                a {
                    background: #019cf6;

                    &:hover {
                        background: shade(#019cf6, 10%);
                    }
                }
            }

            &.social_youtube {
                a {
                    background: #e62119;

                    &:hover {
                        background: shade(#e62119, 10%);
                    }
                }
            }

            &.social_linkedin {
                a {
                    background: #0177b5;

                    &:hover {
                        background: shade(#0177b5, 10%);
                    }
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {

    #footer_wrapper {
        .social_wrapper {
            float: right;
        }
    }
}